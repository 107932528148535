import styled from 'styled-components';

import { devices } from '../../styles';

const Image = styled.div`
  min-width: 444px;
  max-width: 832px;
  flex: 1;
  margin-right: 48px;
  margin-bottom: 48px;

  @media ${devices.mobile} {
    min-width: 100%;
  }
`;

const Content = styled.div`
  width: 416px;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const AboutPage = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

AboutPage.Image = Image;
AboutPage.Content = Content;

export default AboutPage;
