import React from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import ContentTitle from '../components/ContentTitle';
import AboutPage from '../components/AboutPage';

class AboutUs extends React.PureComponent {
  render() {
    const page = get(this, 'props.data.contentfulPage');
    return (
      <Layout>
        <Helmet>
          <title>About Us</title>
        </Helmet>
        <ContentTitle>About Limerence Motor Co.</ContentTitle>
        <AboutPage>
          <AboutPage.Image>
            <Image sizes={page.image.sizes} />
          </AboutPage.Image>
          <AboutPage.Content
            dangerouslySetInnerHTML={{
              __html: page.content.childMarkdownRemark.html,
            }}
          />
        </AboutPage>
      </Layout>
    );
  }
}
export const pageQuery = graphql`
  query PageBySlug {
    contentfulPage(slug: { eq: "about-us" }) {
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        sizes(maxWidth: 832, resizingBehavior: SCALE) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
    }
  }
`;

export default AboutUs;
